.hero-header-wrap {
	height: 550px !important;
	margin-top: 0px; // fix a rendering problem under the menu
	position: relative;
	background-size: cover !important;
	background-position: center center !important;
	background-repeat: no-repeat !important;
	.ui.container.hero-content {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		h1.ui.header {
			width: 70%;
			color: white;
			font-weight: 600;
			font-size: 52px; }
		h2.ui.header {
			width: 70%;
			color: white;
			font-weight: 600;
			font-size: 44px; }
		h3.ui.header {
			width: 70%;
			color: white;
			font-weight: 100;
			font-size: 32px; }

		// .ui.button
		// 	margin-top: 14px
		// 	background-color: transparent
		// 	box-shadow: none
		// 	color: white
		// 	padding: 0
		// 	font-size: 18px
 } }		// 	font-weight: 700 !important

.ui.container {
	.ui.grid {
		.column {
			.ui.grid {
				.row {
					p.description {
						font-size: 16px !important;
						color: #223A66;
						font-weight: 100;
						line-height: 1.6;
						font-weight: 300 !important; }
					h3.ui.header {
						width: 70%;
						color: #223A66;
						font-weight: 100;
						font-size: 28px; } }
				.row.lista {
						padding-top: 2px !important;
						padding-bottom: 2px !important;
						padding-left: 20px;
						i.symbol {
							color: #223A66;
							margin-top: 7px; }
						p.symbol {
							float: right !important; } } } } } }

// Pages Hero Images
#about-Hero {
	background: url(../img/about/header-about-us.jpg);
	box-shadow: inset 0 0 0 2000px rgba(18, 36, 68, 0.75); }
#whatwedo-Hero {
	background: url(../img/what-we-do/what-we-do.jpg);
	box-shadow: inset 0 0 0 2000px rgba(52, 75, 121, 0.6);
 }	//box-shadow: inset 0 0 0 2000px rgba(34,58,102,0.8)
#projects-Hero {
	background: url(../img/projects/projects.jpg);
	box-shadow: inset 0 0 0 2000px rgba(18,36,68,0.5); }

// What we do Hero Images
#mechanicalDesignAndEngineering-Hero {
	background: url(../img/what-we-do/what-we-do-design-1.jpg);
	box-shadow: inset 0 0 0 2000px rgba(18, 36, 68, 0.75);
 }	//background-attachment: fixed !important
#robotSimulationAndProgramming-Hero {
	background: url(../img/what-we-do/what-we-do-robot.jpg);
	box-shadow: inset 0 0 0 2000px rgba(9,29,53,0.7);
 }	//background-attachment: fixed !important
#controlsDesignAndPlcProgramming-Hero {
	background: url(../img/what-we-do/what-we-do-controls-1.jpg);
	box-shadow: inset 0 0 0 2000px rgba(3,16,31,0.7);
 }	//background-attachment: fixed !important
#advancedManufacturing-Hero {
	background: url(../img/what-we-do/what-we-do-mfg.jpg);
	box-shadow: inset 0 0 0 2000px rgba(18,36,68,0.5);
 }	//background-attachment: fixed !important
#SMXAndLaserMeasurement-Hero {
	background: url(../img/what-we-do/what-we-do-measurement.jpg);
	box-shadow: inset 0 0 0 2000px rgba(18,36,68,0.5); }
#lineIntegrationSupport-Hero {
	background: url(../img/what-we-do/what-we-do-line-1.jpg);
	box-shadow: inset 0 0 0 2000px rgba(0,23,64,0.75); }
#softwareDevelopment-Hero {
	background: url(../img/what-we-do/what-we-do-it.jpg);
	box-shadow: inset 0 0 0 2000px rgba(0,23,64,0.75); }
#researchAndInnovation-Hero {
	background: url(../img/what-we-do/what-we-do-research.jpg);
	box-shadow: inset 0 0 0 2000px rgba(18, 36, 68, 0.75); }

// Projects Hero Images
#projectOne-Hero {
	background: url(http://placehold.it/1920x1080); }
#projectTwo-Hero {
	background: url(http://placehold.it/1920x1080); }

// MOBILE VIEW
@media screen and (max-width: 767px) {
	.hero-header-wrap {
		.ui.container.hero-content {
			padding: 40px;
			h2.ui.header {
				width: 100%;
				font-size: 32px !important;
				text-align: center;
				font-weight: 500; }
			h1.ui.header {
				width: 100%;
				font-size: 32px !important;
				font-weight: 500;
				text-align: center; }
			h3.ui.header {
				width: 100%;
				font-size: 24px !important;
				text-align: center; } } } }


