.categoryThumb {
	.ui.grid {
		.wrapperColumn {
			padding: 0 !important;
			.tab {
				.description {
					padding: 18px 0 0 0;
					font-size: 18px; } } }
		.thumbsnav {
			padding-left: 0 !important;
			padding-right: 0 !important;
			padding-bottom: 0 !important;
			padding-top: 26px !important;
			i.next-arrowThumb, i.previous-arrowThumb {
				box-shadow: 0 0 0 1px rgba(0,0,0,.1) inset;
				cursor: pointer; } }
		.row {
			.swiper-container.categoryThumbnail {
				.swip {
					height: 160px;
					position: relative;
					margin: 0; }
				.ui.horizontal.text.swiper-wrapper.tabular.menu.swip {
					position: relative;
					// width: 100%
					margin: 0;
					a.item.swiper-slide {
						background-size: cover;
						background-position: center center;
						background-repeat: no-repeat;
						padding: 0;
						border: none;
						.image {
							.thumbDimmer {
								background-color: rgba(0,0,0,.1) !important; } } } } } } } }


// MOBILE VIEW
@media screen and (max-width: 767px) {
	.column.sixteen.wide.right.aligned.floated.thumbsnav {
		padding: 0 12px; }
	.swiper-container.categoryThumbnail {
		// margin-right: 14px
		.swip {
			height: 0 !important;
			padding: 14px 14px 14px 14px; } } }

// TABLET VIEW
@media (max-width: 991px) and (min-width: 768px) {
	.swiper-container.categoryThumbnail {
		.swip {
			height: 110px !important; } } }
