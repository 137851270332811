#cardsOverview {
	.cards {
		visibility: hidden;
		margin-top: 50px !important;
		margin-bottom: 50px !important;
		.card {
			overflow: hidden;
			height: 310px !important;
			.rise {
				position: relative;
				// bottom: -135px
				left: 0;
				padding: 0px;
				background-color: #fff;
				width: 100%;
				-webkit-transition: 0.5s;
				transition: 0.5s;
				z-index: 999;
				h3.ui.header {
					background-color: #fff;
					padding: 20px;
					i.icon {}
					.content {
						padding-left: 10px;
						font-size: 22px;
						text-transform: uppercase;
						color: #223A66; } }

				.description {
					padding: 0px 5px 0px 25px !important;
					p {
						font-size: 20px !important;
						color: #223A66;
						line-height: 1.6;
						font-weight: 300 !important; } }
				a.button {
					font-size: 18px !important;
					font-weight: 700;
					margin: 0 0 10px 0 !important;
					background-color: white;
					color: #223A66; } }

			.image {
				border-radius: 0 !important;
				z-index: 0;
				img {
					box-shadow:  inset 0 0 0 2000px rgba(0, 82, 236, 0.25);
 } }					// margin-bottom: 86px !important
			// img
			// 	-webkit-transition: transform 0.5s
			// 	transition: transform 0.5s
			//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.4) !important
			&:hover .rise {
				opacity: 1;
				-webkit-transition: transform .5s;
				transition: transform .5s;
				-webkit-transform: translate3d(0,-135px,0);
				transform: translate3d(0,-135px,0);
				visibility: visible; }

			&:nth-of-type(7),
			&:nth-of-type(8) {
				height: 310px; }
			// &:hover img
			// 	-webkit-transform: translate3d(0,0px,0)
			// 	transform: translate3d(0,0px,0)
			// &:hover .divi-btn
			// 	opacity: 1
			// 	-webkit-transform: translate3d(0,-70px,0)
			// 	transform: translate3d(0,-70px,0)
			// &:hover img
			// 	// opacity: 0.2
			// 	-webkit-transition: 0.5s ease !important
 } } }			// 	transition: 0.5s ease

// Mobile small
@media screen and (max-width: 767px) {
	#cardsOverview {
		.cards {
			.card {
				overflow: hidden;
				height: 310px !important;
				.rise {
					h3.ui.header {
						// margin-bottom: 0
						padding: 16px;
						.content {
							font-size: 22px !important; } }
					.description {
						// margin-top: 0
						p {
							font-size: 18px !important; } } }
				.image {
					img {
 } } } } } }						// margin-bottom: 86px !important

// //Mobile portrait
@media screen and (max-width: 320px) and (orientation:portrait) {
	#cardsOverview .cards .card {
		height: 250px !important; } }

// //Mobile portrait
@media screen and (max-width: 375px) and (min-width: 321px) and (orientation:portrait) {
	#cardsOverview .cards .card {
		height: 290px !important; } }

// //Mobile portrait
@media screen and (max-width: 384px) and (min-width: 376px) and (orientation:portrait) {
	#cardsOverview .cards .card {
		height: 300px !important; } }

// //Mobile portrait
@media screen and (max-width: 412px) and (min-width: 385px) and (orientation:portrait) {
	#cardsOverview .cards .card {
		height: 320px !important; } }

// //Mobile portrait
@media screen and (max-width: 600px) and (min-width: 413px) and (orientation:portrait) {
	#cardsOverview .cards .card {
		height: 320px !important; } }


// //Mobile landscape
@media screen and (max-width: 567px) and (min-width: 400px) and (orientation:landscape) {
	#cardsOverview .cards .card {
		height: 360px !important;
		.rise {
			a.button {
				padding-top: 20px; } } } }

// //Mobile landscape
@media screen and (max-width: 666px) and (min-width: 568px) and (orientation:landscape) {
	#cardsOverview .cards .card {
		height: 400px !important;
		.rise {
			a.button {
				padding-top: 40px; } } } }
// //Mobile landscape
@media screen and (max-width: 666px) and (min-width: 568px) and (min-height: 360px) and (orientation:landscape) {
	#cardsOverview .cards .card {
		height: 450px !important;
		.rise {
			a.button {
				padding-top: 40px; } } } }
// //Mobile landscape
@media screen and (max-width: 735px) and (min-width: 667px) and (orientation:landscape) {
	#cardsOverview .cards .card {
		height: 450px !important;
		.rise {
			a.button {
				padding-top: 40px; } } } }

// //Mobile landscape
@media screen and (max-width: 767px) and (min-width: 736px) and (orientation:landscape) {
	#cardsOverview .cards .card {
		height: 520px !important;
		.rise {
			a.button {
				padding-top: 40px; } } } }

