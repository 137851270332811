// @import "1-tools/normalize"
// @import "1-tools/bourbon/bourbon"
@import "1-tools/googlefonts";

// Base Styles
@import "2-basics/base";

// Common Sections
@import "3-partials/footer";
@import "3-partials/menu";

// Common
@import "3-partials/common/introHeroHeader";
@import "3-partials/common/breadcrumbs";

// index.jade Sections
@import "4-pages/home/1-heroSlider";
@import "4-pages/home/2-1-servicesSection";
@import "4-pages/home/2-2-servicesSection";
@import "4-pages/home/3-missionSection";
@import "4-pages/home/4-testimonialsSection";
@import "4-pages/home/5-projectsSlider";
@import "4-pages/home/6-descriptionSection";
@import "4-pages/home/7-2-partners";
@import "4-pages/home/8-callToActionSection";

// about.jade Sections
@import "4-pages/about/1-introduction";
@import "4-pages/about/2-timeline";
@import "4-pages/about/3-teamManagement";

// whatwedo.jade
@import "4-pages/whatwedo/1-cardsOverview";

// whatwedo - categories
@import "4-pages/whatwedo/categories/categoriesLayout";
@import "4-pages/whatwedo/categories/categoryThumbnail";

// projects
@import "4-pages/projects/projectCards";
@import "4-pages/projects/projectContent";

//contact.jade
@import "4-pages/contact/modals/1-load";
@import "4-pages/contact/modals/2-error";
@import "4-pages/contact/modals/3-success";

@import "4-pages/contact/1-contactForm";
@import "4-pages/contact/2-googleMap";

@import "4-pages/policies/policies";
@import "4-pages/policies/cookieconsent.sass";
