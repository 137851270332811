html,body,p,h1,h2,h3,h4,h5,h6,div,.button {
	font-family: 'Exo 2' !important; }

body {
	background-color: white;
	line-height: 1.6;
	font-weight: 100;
	font-size: 14px;
	color: #666;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Exo 2' !important; }

.animating.dimmable:not(body),
.dimmed.dimmable:not(body) {
	overflow: visible !important; }

.ui.dropdown.item.wwd {
	.menu {
		margin-top: 0 !important;
		border-radius: 0 !important;
		border: 0 !important;
		box-shadow: 0px 3px 4px gray, 0px 3px 4px transparent !important; }
	&.visible {
		.menu {
			.item {
				color: #223A66 !important; } } } }

p {
	font-size: 16px !important;
	font-weight: 400; }


i.circular {
	box-shadow: 0 0 0 !important; }

h1,h2,h3,h4,h5,h6 {
	font-weight: 400; }


// remove margins on grids
.ui.grid {
	margin: 0 !important; }

.sub.header {
	line-height: 1.6;
	font-family: 'Exo 2'; }

// content font
.content-wrap {
	font-weight: 100; }


// menu font
.menu-wrap .ui.container .ui.right.menu a {
	font-family: 'Exo 2', sans-serif;
	font-weight: 100;
	font-size: 20px; }


// links
// a
// 	&:hover,
// 	&:focus
// 		color: lighten(#0074D9, 20%)


// iOS fix
html.ios {
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch; }

html.ios,
html.ios body {
	height: initial; }

