#projectCards {
	padding: 50px 0 !important;
	.ui.two.cards.stackable.doubling {
		.ui.card {
			transition: .25s ease-in-out;
			&:hover {
				box-shadow: 0 0px 20px gray;
				transition: .25s ease-in-out;
				cursor: default; }

			.ui.image {
				img {} }
			.content {
				padding: 0 0 14px 0 !important;
				h2.ui.header {
					padding: 14px 16px 14px 16px;
					margin: 0 !important;
					// color: #5C5C5C
					color: #fff;
					font-weight: 100;
					text-align: center;
					font-size: 24px;
					background-color: rgba(68, 87, 122, 1); }
				.ui.divider {
					margin: 0 !important; }
				.ui.horizontal.list {
					padding: 14px 16px;
					width: 100%;
					.item {
						i.icon {
							color: #223A66;
							font-size: 30px; }
						.content {
							padding: 0 !important;
							.header {
								font-size: 18px;
								color: #5C5C5C;
								font-weight: 400; } } } }
				.ui.divider {}
				p.description {
					padding: 14px 16px;
					line-height: 1.5;
					letter-spacing: 0.25; } }
			.button {
				// margin: 0 16px
				background-color: #fff;
				border-top: 1px solid #D4D4D5;
				border-right: 1px solid #D4D4D5;
				border-left: 1px solid #D4D4D5;
				color: #223A66;
				font-size: 20px;
				font-weight: 100;
				.visible.content {
					padding-bottom: 0px !important; }
				&:hover {
					background-color: #223A66;
					color: #fff; } } } } }

// Mobile
@media screen and (max-width: 767px) {
	#projectCards {
		.ui.two.cards.stackable.doubling {
			.ui.card {
				margin: 20px auto;
				.content {
					.header {
						font-size: 18px !important; }
					.ui.horizontal.list {
						.item {
							margin-left: 0 !important;
							i.icon {
								font-size: 20px; }
							.content {
								.header {
									font-size: 14px !important; } } } } } } } } }
