#descriptionSection {
	padding: 40px 0;
	background: #ffffff;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	.ui.container {
		h2.ui.header {
			color: #0E365A;
			font-size: 32px;
			line-height: 1.6;
			span {
				color: orange; } } } }

// MOBILE VIEW
@media screen and (max-width: 767px) {
	#descriptionSection {
		.ui.container {
			h2.ui.header {
				font-size: 22px;
				span {} } } } }
