#servicesSection-cards {
	padding-top: 80px;
	padding-bottom: 80px;
	box-shadow: inset 0 0 0 2038px rgba(34, 58, 102, 0.5) !important;
	// box-shadow: inset 0 0 0 2038px rgba(0,34,45,0.8) !important
	background: url(../img/home-services/background-services.jpg) center center !important;
	background-attachment: fixed !important;
	.ui.container {
		.ui.grid {
			.column:nth-of-type(1) {
				h2.header {
					color: #ffffff;
					font-size: 32px;
					line-height: 1.8; } }
			.column:nth-of-type(2) {
				border-left: 1px solid lightgray;
				.description {
					font-size: 16px;
					line-height: 1.6; } } }
		.ui.three.stackable.doubling.centered.cards {
			.ui.card {
				background-color: #ffffff;
				padding-bottom: 10px !important;
				.ui.image {
					img {}
					border-color: #000; }
				.content {
					padding-left: 15px;
					padding-right: 15px;
					h4.ui.header {
						font-size: 19px;
						color: #223A66; }
					.ui.divider {
						margin: 10px 0; }
					p.description {
						font-size: 16px;
						font-weight: 100;
						line-height: 1.8; } }
				a {
					font-size: 14px;
					font-weight: 900;
					padding-left: 15px;
					margin-bottom: 20px; } } } } }

// MOBILE VIEW
@media screen and (max-width: 767px) {
	#servicesSection {
		.ui.container {
			.ui.grid {
				.column.four.wide {
					h2.header {
						color: #0E365A;
						text-align: left !important;
						margin: 0;
						float: left;
						font-size: 18px; } }
				.column.twelve.wide {
					border-left: none;
					.description {} } }
			.ui.three.stackable.doubling.centered.cards {
				.ui.card {

					padding-bottom: 20px !important;
					&:not(:first-of-type) {
						margin-top: 40px; }
					.ui.image {
						img {} }
					.content {
						h4.ui.header {
							font-size: 18px; }
						.ui.divider {}
						p.description {} } } } } } }
// TABLET VIEW
@media (max-width: 991px) and (min-width: 768px) {
	#servicesSection {
		.ui.container {
			.ui.grid {
				.column.four.wide {
					h2.header {
						color: #0E365A;
						text-align: left !important;
						margin: 0;
						float: left;
						font-size: 18px; } }
				.column.twelve.wide {
					border-left: none;
					.description {} } }
			.ui.three.stackable.doubling.centered.cards {
				.ui.card {
					padding-bottom: 20px !important;
					&:not(:first-of-type) {
						margin-top: 40px; }
					.ui.image {
						img {} }
					.content {
						h4.ui.header {
							font-size: 18px; }
						.ui.divider {}
						p.description {} } } } } } }
