#missionSection {
	padding: 30px 0;
	.ui.container {
		.ui.stackable.grid {
			.column.sixteen.wide {
				.row {
					h2.ui.header {
						width: 100%;
						color: #0E365A;
						font-size: 32px;
						line-height: 1.6;
						text-transform: uppercase; }
					h4.ui.header {
						width: 100%;
						font-size: 22px;
						font-weight: 100;
						color: #5F5A5A;
						line-height: 1.6;
						color: #2D6293; } } } } } }

// MOBILE VIEW
@media screen and (max-width: 767px) {
	#missionSection {
		padding: 30px 0;
		.ui.container {
			.ui.stackable.grid {
				.column.sixteen.wide {
					.row {
						h2.ui.header {
							font-size: 16px;
							font-weight: 100;
							text-transform: uppercase; }
						h4.ui.header {
							font-size: 14px; } } } } } } }

// TABLET VIEW
// @media (max-width: 991px) and (min-width: 768px)
