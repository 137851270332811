.wrap-form-and-map {
	position: relative;
	height: 1000px;
	.ui.middle.aligned.list.wrap-header {
		background-color: rgba(255,255,255,1);
		position: relative;
		padding: 20px;
		margin: 0;
		z-index: 1;
		// border: 1px solid lightgray
		border-top: 0;
		.item {
			.right.floated.content {
				.toggle-form {
					position: relative;
					height: 46px;
					width: 46px;
					text-align: center;
					cursor: pointer;
					border-radius: 3px;
					border: 1px solid #223A66;
					i.angle {
						position: relative;
						top: 50%;
						left: -3px;
						transform: translateY(-56%);
						color: #223A66;
						&:before {
							font-size: 40px; } } } }
			.content {
				h1.header {
					font-weight: 100;
					color: #223A66;
					font-size: 26px;
					.sub.header {
						font-size: 20px;
						font-weight: 100; } } } } }

	form.ui.form {
		padding: 60px 20px;
		box-shadow: 0px 0px 300px -11px rgba(0,0,0,0.75);
		background-color: rgba(255,255,255,0.9);
		border-bottom-right-radius: 5px;
		border-bottom-left-radius: 5px;
		z-index: 0;
		.field {
			padding: 5px 10px !important;
			label {
				font-size: 20px;
				font-weight: 100; }
			textarea {
				font-size: 24px !important;
				padding-left: 10px;
				padding-top: 10px; }
			.ui.left.icon.input {
				input {
					height: 50px;
					padding-left: 50px !important;
					padding-top: 2px !important;
					padding-bottom: 2px !important;
					font-size: 20px; }
				i.icon {
					font-size: 24px; } }
			.button {
				transition: .3s ease-in-out;
				// &:hover
				// 	transition: .3s ease-in-out
				&.opac {
					opacity: 0.4; } } } } }
// mobile view
@media screen and (max-width: 767px) {
	.wrap-form-and-map {
		.ui.middle.aligned.list.wrap-header.container {
			margin: 0 !important;
			.item {
				.content {
					h1.header {
						font-size: 20px;
						.sub.header {
							font-size: 14px;
							font-weight: 100; } } } } }
		form.ui.form.container {
			padding: 40px 20px;
			box-shadow: 0px 0px 0px 0px;
			margin: 0 !important; } } }


@supports (backdrop-filter: blur(10px)) {
	.wrap-form-and-map {
		form.ui.form {
			background-color: rgba(255,255,255,0.6) !important;
			-webkit-backdrop-filter: blur(10px) !important;
			backdrop-filter: blur(10px) !important; } } }
