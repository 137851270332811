#testimonialsSection {
	.ui.grid {
		.column.seven {
			box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.7);
			background-image: url('../img/home-services/testimonials-backgrond.jpg');
			background-position: center center;
			background-size: cover;
			height: 500px; }
		.column.nine {
			height: 500px;
			background-color: rgba(14,54,90, 1);
			.swiper-container.testimonials {
				height: 380px;
				margin: 5vw 10vw 5vw 5vw;
				.swiper-wrapper {
					.swiper-slide {
						.ui.header.inverted {
							position: relative;
							font-weight: 400;
							font-size: 24px;
							img {
								background: #fff {
    							padding: 10px; }
								width: 120px; }
							.content {
								line-height: 1.6;
								.sub.header {
									font-size: 22px !important;
									line-height: 1.8;
									font-weight: 400; }
								.icons {
									margin-top: 10px; } } }
						.description {
							font-size: 18px;
							color: white;
							font-weight: 100;
							line-height: 1.6; } } }
				.swiper-pagination {
					text-align: left;
					margin-bottom: 20px;
					.swiper-pagination-bullet {
						background: #ffffff;
						width: 10px;
						height: 10px; } } } } } }

// MOBILE VIEW
@media screen and (max-width: 767px) {
	#testimonialsSection {
		.ui.grid {
			.column.seven {
				display: none; }
			.column.nine {
				.swiper-container.testimonials {
					margin: 1vw;
					height: inherit;
					.swiper-wrapper {
						margin-bottom: 50px;
						.swiper-slide {
							.ui.header {
								text-align: center; }
							.description {
								text-align: center;
								font-size: 16px;
								color: white;
								font-weight: 100;
								line-height: 1.4; } } }
					.swiper-pagination {
						text-align: center; } } } } } }



// TABLET VIEW
@media (max-width: 991px) and (min-width: 768px) {
	#testimonialsSection {
		.ui.grid {
			.column.seven {}
			.column.nine {
				padding-top: 80px;
				.swiper-container.testimonials {
					margin: 1vw !important; } } } } }







