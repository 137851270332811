.breadcrumbs {
	background-color: #EDEAEA;
	height: 60px;
	.ui.container {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		.ui.breadcrumb {
			a.section {
				color: #223A66;
				font-size: 18px;
				&.active {} }
			i.icon {} } } }
