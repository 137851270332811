.ui.sticky.wrap {
	.menu-wrap {
		width: 100% !important;
		&.placeholder {
			display: none !important; }
		.ui.borderless.menu {
			border-radius: 0 !important;
			width: 100% !important;
			background-color: #223A66;
			box-shadow: none !important;
			border: 0 !important;
			.ui.container {
				// background-color: white
				background-color: #223A66;
				a.header {
 }					// visibility: hidden
				.ui.text.menu.computer {
					transition: .6s opacity !important;
					padding: 0 !important;
					.dropdown.item.wwd {
						font-size: 20px;
						font-family: 'Exo 2';
						padding: 20px 0;
						text-transform: uppercase;
						font-weight: 700;
						a {
							color: white !important;
							&:hover {
								color: #b0d6ff !important; } }
						&.active {
							a {
								color: #b0d6ff !important; } }
						.menu {
							// background-color: #223A66
							background-color: #fff;
							.item {
								span {
									color: #223A66; }
								&:hover {
									background-color: #223A66 !important;
									span {
										color: #fff; } }
								&.active {
									background-color: #223A66 !important;
									span {
										color: #fff; } } } } }
					.item:not(.wwd) {
						font-size: 20px;
						padding: 20px 0;
						font-family: 'Exo 2';
						border: 0 !important;
						font-weight: 700;
						text-transform: uppercase;
						// color: #2f3235
						color: white !important;
						transition: .6s opacity !important;
						a {
							color: white; }
						&.active {
							// color: #2185D0
							color: #b0d6ff !important;
							// background-color: #edeaea
							background-color: #223A66;
							a {
								color: #223A66; } }
						&:hover {
							// color: #4291c9
							color: #b0d6ff !important; }
						&.logo {
							padding: 0 !important;
							img.ui.image {
								height: 55px; } } }
					&.five {
						transition: .6s opacity !important; }
					&.six {
						transition: .6s opacity !important; }
					&.seven {
						transition: .6s opacity !important; } }
				.right.menu.tablet.mobile {
					border: 0;
					border-radius: 0;
					background-color: #223A66;
					.lang {
						.dropdown {
							.text {
								color: #fff;
								padding: 10px;
								border-radius: 5px;
								border: 1px solid #fff !important; }
							&.active {
								background-color: transparent; } } }
					.item.launch {
						i {
							margin: 0 auto !important;
							color: #fff; } } } } } }
	&.fixed {
		.menu-wrap {
			.ui.borderless.menu {
				box-shadow: 0 1px 2px 0 rgba(34,36,38,.15) !important; } } } }

.dropCategories {
	background-color: #223A66 !important;
	.item {
		span {
			color: #fff !important; } } }


.dropaccordion {
	padding: 0 !important;
	&.active {
		background-color: transparent !important; }
	.content {
		background-color: transparent !important;
		padding: 5px 0px !important;
		.menu.text {
			.item {
				margin: 0 !important;
				padding: 10px 18px !important;
				font-size: 14px !important; } } }
	.content.active {
		background-color: transparent;
		padding: 5px 0px !important;
		.menu.text {
			.item {
				margin: 0 !important;
				padding: 10px 18px !important;
				font-size: 14px; } } }
	.title {
		padding: 13px 16px !important; } }



.menuWithLogo.ui.grid.computer.only {
	.ui.container {
		.ui.horizontal.list.right.floated {
			margin-top: 26px;
			.ui.language.dropdown.link.item {
				.text {
					border: 1px solid #223A66;
					border-radius: 5px;
					padding: 14px;
					font-size: 18px;
					color: #223A66;
					user-select: none;
					&:hover {
						background-color: #223A66 !important;
						color: white !important;
						transition: .2s ease-in-out; } }
				.ui.vertical.menu {
					.ui.header {
						padding: 5px 20px !important;
						font-size: 14px;
						font-weight: 100; }
					.ui.scrolling.menu.dropLang {
						.ui.item {
							padding: 20px !important;
							font-size: 18px;
							font-weight: 100;
							&.active {
								background-color: #F8F8F8 !important; } } } } }
			.item.email {
				position: relative;
				bottom: 2px;
				i.large.mail.middle.aligned.icon {}
				.content {
					.header {
						font-size: 18px;
						font-weight: 100;
						color: #223A66 !important; } } }
			.item {
				.button.reqQuoteBtn {
					padding: 14px;
					font-size: 18px;
					font-weight: 100;
					transition: .2s ease-in-out;
					background-color: #223A66 !important;
					color: white !important;
					&:hover {
						background-color: white !important;
						color: #223A66 !important;
						transition: .2s ease-in-out;
						box-shadow: 0 0 0 1px rgb(34, 58, 102) !important; } } } } } }

// used for components for which we cant use .mobile.only classes
.mobile-only {
	display: none !important;
	.gmab-logo-mobile {
		width: 70px !important; } }
// adjust hero margin to after the menu sticks to the top of the page in order for it not to snap -- used in js
.hero-top {
	margin-top: 70px !important; }

// adjust top margin for the top sidebar on mobile -- used in js
.ui.top.vertical.sidebar.menu.mobileMenu.preTopbefore {
	margin-top: 125px !important; }
.ui.top.vertical.sidebar.menu.mobileMenu.preTop {
	margin-top: 75px !important; }

.menuWithLogo {
	background-color: white;
	padding: 20px 0 !important; }

.pre-menu {
	.row {
		z-index: 99999999999999 !important;
		padding: 0 !important;
		background-color: #edeaea;
		color: #223A66;
		font-family: 'Exo 2';
		font-weight: 500; } }
.logo {
	transition: .6s opacity !important;
	opacity: 1 !important; }

.logo.mobile-only {
	opacity: 0;
 }	// transition: .6s all !important

@media screen and (max-width: 767px) {
	.hero-top {
		margin-top: 72px !important; }
	.mobile-only {
		display: block !important;
		width: 70px !important;
 }		// transition: 1s ease-in-out !important

	.pre-menu {
		.row {
			.container {
				.grid {
					.column.left.floated {
						font-size: 12px;
						padding: 16px 0 !important; } } } } }

	// .active
	// 	background-color: #F5F5F5 !important
	.ui.borderless.menu {
		.ui.container {
			z-index: 999999999999 !important;
			margin: 0 !important;
			a.header.item {
				padding: 10px !important;
				span {
					margin-left: 5px; } }
			.ui.right.menu.grid.tablet.mobile.only {
				padding: 10px 0 !important;
				.item.lang {
					padding: 0 13px 0 13px !important;
					.ui.language.dropdown.link.item {
						padding: 0 !important;
						.text {
							border: 1px solid black;
							border-radius: 5px;
							padding: 10px !important; } } } } } } }

@media (max-width: 991px) and (min-width: 768px) {
	.hero-top {
		margin-top: 90px !important; }
	.mobile-only {
		display: block !important;
		transition: 1s ease-in-out !important; }
	.ui.borderless.menu {
		.ui.container {
			z-index: 999999999999 !important;
			margin: 0 !important;
			width: 100%; } }
	.ui.top.vertical.sidebar.menu.mobileMenu.preTopbefore {
		margin-top: 137px !important; }
	.ui.top.vertical.sidebar.menu.mobileMenu.preTop {
		margin-top: 87px !important; } }


