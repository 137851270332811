//#hero-slider
///height: calc(100vh - 210px)
#hero-slider {
	.swiper-wrapper {
		.swiper-slide {
			// box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.3) !important
			background-size: cover !important;
			background-position: center center !important;
			background-repeat: no-repeat !important;
			&:nth-of-type(1) {
				background: url(../img/hero-header/hero-header-slide-design-and-engineering-v2.jpg);
				box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.3);
 }				//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.4);
			&:nth-of-type(2) {
				background: url(../img/hero-header/hero-header-slide-simulation-v2.jpg);
				box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.2);
 }				//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.4);
			&:nth-of-type(3) {
				background: url(../img/hero-header/hero-header-slide-controls-v2.jpg);
				box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.4);
 }				//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.5);
			&:nth-of-type(4) {
				background: url(../img/hero-header/hero-header-slide-manufacturing-v1.jpg);
				box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.5);
 }				//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.4);
			&:nth-of-type(5) {
				background: url(../img/hero-header/hero-header-slide-smx-v1.jpg);
				box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.5);
 }				//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.4);
			&:nth-of-type(6) {
				background: url(../img/hero-header/hero-header-slide-line-integration-v2.jpg);
				box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.5);
 }				//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.5);
			&:nth-of-type(7) {
				background: url(../img/hero-header/hero-header-slide-it.jpg);
				box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.3);
 }				//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.4);
			&:nth-of-type(8) {
				background: url(../img/hero-header/hero-header-slide-training-v1.jpg);
				box-shadow:	inset 0px 0px 0px 2000px rgba(8, 42, 84, 0.7);
 }				//box-shadow: inset 0px 0px 0 2000px rgba(0,0,0,0.4);
			.ui.container {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				.grid {
					height: 400px;
					.column {
						&:nth-of-type(1) {
							position: relative;
							h1.header {
								color: white;
								font-weight: 600;
								font-size: 52px; }
							h3.header {
								color: white;
								font-weight: 100;
								font-size: 28px; } } } } } } }

	// slider overwrites
	.swiper-pagination {
		margin-bottom: 20px;
		.swiper-pagination-bullet {
			width: 16px;
			height: 16px; } } }


.secondSection {
    height: 1000px;
    background: orange; }


// MOBILE VIEW
@media screen and (max-width: 767px) {
	#hero-slider {
		.swiper-slide {
			.ui.container {
				.grid {
					.column {
						&:nth-of-type(1) {
							padding: 40px;
							h1.header {
								font-size: 32px !important;
								font-weight: 500;
								text-align: center; }
							h3.header {
								font-size: 24px !important;
								text-align: center; } }
						&:nth-of-type(2) {
							display: none; } } } } } } }



// TABLET VIEW
@media (max-width: 991px) and (min-width: 768px) {
	#hero-slider {
		.swiper-slide {
			.ui.container {
				.grid {
					.column {
						&:nth-of-type(1) {
							padding: 40px;
							h1.header {
								font-size: 36px !important;
								font-weight: 500;
								text-align: center; }
							h3.header {
								font-size: 28px !important; } }
						&:nth-of-type(2) {
							display: none; } } } } } } }
