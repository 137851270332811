.footer-wrap {
	background-color: #2A3035;
	font-weight: 400;
	position: relative;
	.footer {
		color: white; // color for text inside footer
		.row:first-of-type {
			padding: 100px 0 !important; // padding for the footer excluding the row with copyright text
			.column {
				// footer headers
				h4 {
					font-weight: 400 !important; // very bold
					font-size: 20px;
					color: #26a2fd; } // blue

				&:nth-of-type(2) {
					h4 {} }

				.list {
					.item {
						// font-weight: 900 !important
						a {
							color: white;
 } } }							// font-weight: 900 !important
				// various optimizations for the items inside footer columns to show at the same level
				&:nth-of-type(1) {
					div.item {
						padding: 19px 0; } }
				&:nth-of-type(2) {
					div.item {
						padding: 10px 0; } }

				&:nth-of-type(3) {
					margin-top: 25px;
					div.item {
						padding: 10px 0; } }
				&:nth-of-type(4) {
					div.item {
						padding: 17px 0;
						&:last-of-type {
							// background-color: red
							padding: 13px 0; } } } } } } }


// bottom row of the footer with copyright text
.copyright {
	padding: 24px 0;
	background-color: #20282D;
	color: lightgray;
	.container {
		.grid {
			.column {
				padding: 0 !important;
				&.right {
					a {
						i.icon {
							font-size: 24px;
							color: #fff;
							&.facebook:hover {
								color: #3A5896; }
							&.google:hover {
								color: #DC4A38; }
							&.linkedin:hover {
								color: #0077B5; }
							&.youtube:hover {
								color: #DE2C28; } } } } } } } }

// mobile view
@media screen and (max-width: 767px) {
	.copyright {
		.container {
			.grid {
				.column {
					text-align: center !important; } } } }

	.footer {
		img.ui.mini.image {
			display: inline !important; }
		.content.header-font {
			font-size: 14px !important; }
		.row:first-of-type {
			.column {
				// some optimizations in order for column 2 and 3 of the footer to appear only as one column on mobile without spaces between them.
				&:nth-of-type(2) {
					padding-bottom: 0px !important; }
				&:nth-of-type(3) {
					padding-top: 0px !important;
					h4.item:nth-child(1) {
						border-bottom: 1px solid rgba(63, 68, 73,0.9); }
					.item:nth-child(1) {
						border-top: 0px;
						padding-top: 0px !important; } } } } } }




// all views except mobile
@media screen and (min-width: 768px) {
	.footer {
		img.ui.mini.image {
			display: none; }
		.content.header-font {
			font-size: 14px; } } }

// all views starting from small monitors -- except mobile and tablet
@media screen and (min-width: 991px) {
	// various optimizations in order for the items inside footer columns to appear at the same line with the company logo (needed because the text for some items was too long and thus it snapped under the logo)
	.footer {
		img.ui.mini.image {
			display: inline; }
		.row {
			.column:nth-of-type(2) {
				.item {
					&:nth-of-type(1) {
						.content.header-font {
							font-size: 13.5px; } }
					&:nth-of-type(4) {
						.content.header-font {
							font-size: 11.4px; } }
					&:nth-of-type(5) {
						.content.header-font {
							font-size: 12.1px; } } } }
			.column:nth-of-type(3) {
				.item {
					&:nth-of-type(4) {
						.content.header-font {
							.description {
								font-size: 11.3px; } } } } } } } }


// all views starting from large monitors -- except mobile, tablet and laptop
@media screen and (min-width: 1201px) {
	// various optimizations in order for the items inside footer columns to appear at the same line with the company logo (needed because the text for some items was too long and thus it snapped under the logo)
	.footer {
		img.ui.mini.image {
			display: inline; }
		.row {
			.column:nth-of-type(2) {
				.item {
					&:nth-of-type(1) {
						.content.header-font {
							font-size: 14px; } }
					&:nth-of-type(4) {
						.content.header-font {
							font-size: 14px; } }
					&:nth-of-type(5) {
						.content.header-font {
							font-size: 14px; } } } }
			.column:nth-of-type(3) {
				.item {
					&:nth-of-type(4) {
						.content.header-font {
							.description {
								font-size: 14px !important; } } } } } } } }

// tablet view
@media (max-width: 991px) and (min-width: 768px) {
	.footer {
		.row {
			.column:nth-of-type(2),
			.column:nth-of-type(3) {
				.list {
					.item {
						img {
							display: none; } } } } } } }

