#timeline {
	padding: 10px 0;
	.ui.feed {
		padding: 10px 0;
		.event {
			padding: 0 !important;
			.label {
				padding: 0 0 140px 0;
				.dot {
					.date {
						color: #223A66;
						left: -2rem;
						top: -1rem;
						position: relative;
						font-size: 24px;
						font-weight: 700; } } }
			.content {
				margin-top: 0 !important;
				.summary {
					.header {
						font-size: 20px;
						position: relative;
						top: -0.2rem;
						color: #223A66; } }
				.extra {
					margin-top: 0 !important;
					padding-bottom: 20px;
					max-width: 100%;
					font-size: 16px !important;
					color: #223A66;
					font-weight: 100;
					line-height: 1.6; } } } } }




.ui.feed > .event .label {
	border-left: 3px solid #DDDDDD; }

.ui.feed > .event:last-child .label {
	border-left-color: transparent; }

.ui.feed > .event > .label {
	margin-left: 6em; }

.ui.feed > .event > .label > img,
.ui.feed > .event > .label > .icon,
.ui.feed > .event > .label > .dot {
	background-color: #fff;
	border: 2px solid #223A66;
	border-radius: 500rem;
	color: #FFFFFF;
	width: .8rem;
	height: .8rem;
	line-height: 1.5;
	left: -0.5rem;
	opacity: 1;
	position: relative; }

@media screen and (max-width: 767px) {
	.ui.feed > .event > .label {
		margin-left: 3em; }

	.ui.feed {
		padding: 30px 0 !important;
		.event {
			.label {
				padding: 0 0 40px 0 !important;
				width: 2px !important;
				.dot {
					.date {
						left: -1rem !important;
						top: -0.5rem !important;
						font-size: 14px !important; } } }
			.content {
				.summary {
					.header {
						font-size: 20px;
						top: -0.2rem; } }
				.extra {
					font-size: 16px; } } } } }
