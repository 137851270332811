#callToActionSection {
	padding: 20px 0;
	position: relative;
	//- background: url('http://placehold.it/1920x1080')
	//-box-shadow: inset 0 0 0 2000px rgba(0,0,0,0.5)
	background: #0E365A;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	.ui.container {
		.ui.stackable.grid {
			.column.twelve.wide {
				h2.ui.header {
					font-size: 28px;
					font-weight: 100; } }
			.column.four.wide {
				.ui.button {
					box-shadow: 0 0 0 1px rgba(255,255,255,1) inset !important;
					color: white !important;
					&:hover {
						background-color: rgba(255,255,255,0.1) !important; }
					i.ui.right.arrow.icon {} } } } } }



// MOBILE VIEW
@media screen and (max-width: 767px) {
	#callToActionSection {
		.ui.container {
			.ui.stackable.grid {
				.column.twelve.wide {
					h2.ui.header {
						font-size: 16px;
						text-align: center; } }
				.column.four.wide {
					text-align: center;
					.ui.button {
						&:hover {}
						i.ui.right.arrow.icon {} } } } } } }

// TABLET VIEW
// @media (max-width: 991px) and (min-width: 768px)
