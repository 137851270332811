#servicesSection-1 {
	padding-top: 10px;
	padding-bottom: 10px;
	background: #ffffff;
	.ui.container {
		.ui.grid {
			.column:nth-of-type(1) {
				h2.header {
					color: #0E365A;
					font-size: 32px;
					line-height: 1.6; } }
			.column:nth-of-type(2) {
				border-left: 1px solid lightgray;
				.description {
					font-size: 16px;
					line-height: 1.6; } } } } }

// MOBILE VIEW
@media screen and (max-width: 767px) {
	#servicesSection {
		.ui.container {
			.ui.grid {
				.column.four.wide {
					h2.header {
						color: #0E365A;
						text-align: left !important;
						margin: 0;
						float: left;
						font-size: 18px; } }
				.column.twelve.wide {
					border-left: none;
					.description {} } } } } }

// TABLET VIEW
// @media (max-width: 991px) and (min-width: 768px)
