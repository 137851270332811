// title for each section inside about page
.title {
	.ui.header {
		text-transform: uppercase;
		font-size: 28px;
		font-weight: 700;
		color: #223A66;
		i.icon {}
		.content {} } }
#introduction {
	padding: 50px 0;
	.grid {
		padding: 30px 0;
		.column {
			&:nth-of-type(1) {
				p {
					font-size: 18px !important;
					color: #223A66;
					font-weight: 100;
					line-height: 1.6; } }
			&:nth-of-type(2) {
				background: url(../img/about/about_picture.jpg);
				box-shadow:  inset 0 0 0 2000px rgba(0, 82, 236, 0.25);
				background-size: cover;
				background-position: center center;
				background-repeat: no-repeat; } } } }


@media screen and (max-width: 767px) {
	.title {
		.ui.header {
			font-size: 20px; } }
	#introduction {
		.grid {
			padding: 30px 0;
			.column {
				&:nth-of-type(2) {
					height: 200px; } } } } }
