#projectsSlider {
	padding: 40px 0;
	position: relative;
	user-select: none;
	.ui.container {
		.grid {
			.row:nth-of-type(1) {
				.column.right.floated {
					padding: 0 !important;
					.prev-arrow,.next-arrow {
						background-color: #f2f2f2;
						height: 30px;
						width: 30px;
						text-align: center;
						cursor: pointer;
						color: #1E70BF;
						&:before {} } }
				.column.left.floated {
					padding: 0 !important;
					.ui.header {
						font-weight: 100; } } }
			.row:nth-of-type(2) {
				.swiper-container.projectsSlider {
					height: 300px;
					.swiper-wrapper {
						.swiper-slide {
							.card {
								position: relative !important;
								top: 50% !important;
								transform: translateY(-50%) !important;
								.blurring.dimmable.image {
									.ui.dimmer {
										background: rgba(0,0,0,0.4);
										.content {
											.center {
												padding: 30px;
												p.description {
													text-align: left; }
												.ui.blue.button {} } } }
									img {} } } } } } } } } }

// MOBILE VIEW
@media screen and (max-width: 767px) {
	#projectsSlider {
		.ui.container {
			.grid {
				.row {
					&:nth-of-type(1) {
						.column.left.floated {
								.ui.header {
									font-size: 22px; } }
						.column.right.floated {
							.prev-arrow,.next-arrow {
								&:before {} } } }
					&:nth-of-type(2) {
						.swiper-container.projectsSlider {
							.swiper-wrapper {
								.swiper-slide {
									img {} } } } } } } } } }




// TABLET VIEW
// @media (max-width: 991px) and (min-width: 768px)
