#teamManagement {
	padding: 0 0 50px 0;
	.cards {
		padding: 50px 0;
		.ui.card {
			.image {
				background-size: cover !important;
				background-position: top center !important;
				box-shadow:	inset 0px 0px 0px 2000px rgba(9, 29, 53, 0.3) {
    			background-size: cover; }
				overflow: hidden !important;
				height: 400px !important; } } } }
// add some transition time to the overlay and the title text
.overlay, .social-links {
	transition: all 0.3s ease-in-out; }

// create a portrait aspect over the image
.innerborder {
	outline: 1px solid #fff;
	outline-offset: -14px;
	position: absolute !important;
	z-index: 1;
	width: 100%;
	height: 100%; }

// create a overlay box over the image
.overlay {
	background: rgba(166, 177, 237, 0.2);
	// make it invisible
	height: 0px;
	width: 0px;
	// position it in the center of the box with the image
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	margin: 0 auto; }

// make the overlay grow and fit the box when the user hovers over the card (its parent)
#teamManagement .cards .card:hover:hover .overlay {
	height: 100%;
	width: 100%; }

// create the social links group of icons
.social-links ul {
	padding: 0;
	margin: 0 auto;
	width: 80%;
	li {
		position: relative;
		display: inline-block;
		width: 32%;
		height: 50px;
		text-align: center;
		z-index: 9999;
		i {} } }

// create the wrapper for the social links and hide it below the bottom of the card
.social-links {
	bottom: -340px;
	position: relative;
	background-color: transparent;
	z-index: 1; }

// when the use hovers over the card make the social links box rise and also change some styles for the box with the title under the card
#teamManagement .cards .card:hover {
	.extracontent {
		background-color: #223A66 !important;
		.header {
			color: #fff !important; } }
	.social-links {
		bottom: 50px;
		transition: all 0.3s ease-in-out; } }

.extracontent {
	background-color: #fff !important;
	transition: .3s ease-in-out;
	.header {
		color: #223A66 !important; } }
