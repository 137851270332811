
#policies {
  padding: 50px 0;
  p,ul,ol {
    font-size: 18px !important;
    color: #223A66;
    font-weight: 100;
    line-height: 1.6; } }

.subtitle {
  margin-top: 10px;
  margin-bottom: 10px;

  .header {
    font-size: 22px !important;
    position: relative;
    top: -0.2rem;
    color: #223A66 !important; }
  .subheader {
    font-size: 18px !important; } }

.date {
  float:right !important {} }
.mailLink {
  font-size: 20px; }

.bold {
  font-weight: bold; }
.f-right {
  float: right; }
