#partners {
	padding-bottom: 60px;
	box-shadow: inset 0 0 0 2038px rgba(255, 255, 255, 0.8) !important;
	background: url(../img/home-services/background-services-01.jpg) center center !important;
	background-attachment: fixed !important;
	.ui.container {
		.ui.stackable.grid {
			.column.sixteen.wide {
				h2.ui.header {
					color: #0E365A;
					font-size: 32px;
					font-weight: 100;
					line-height: 1.6;
					padding-top: 60px;
					padding-bottom: 40px; } } } } }
// MOBILE VIEW
@media screen and (max-width: 767px) {
	#partners {
		.ui.container {
			.ui.stackable.grid {
				.column.sixteen.wide {
					h2.ui.header {
						font-size: 20px;
						text-align: center;
						color: #0E365A;
						padding-top: 30px;
						padding-bottom: 20px; } } } } } }
