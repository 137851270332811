.railContainer {
	.four.wide.column, .seven.wide.column {
		// padding-right: 0 !important
		z-index: 1;
		background-color: #fff; } }
.railMenu {
	padding-bottom: 50px;
	.card {
		.ui.header.attached {
			background-color: lightgray;
			span {} }
		.content {
			padding: 0 !important;
			.ui.divider.list {
				.item {
					i.icon {
						padding-right: 16px; }
					.content {
						.header {
							font-weight: 400 !important;
							-webkit-user-select: text !important; }
						.description {} } } } } }
	.contactInfo {
		margin-top: 4em !important; } }
.railMenu {
	.divider {
		margin: 0 !important;
		border-bottom: 0 !important; }
	a.item {
		margin: 0 !important;
		padding: 18px !important;
		font-size: 16px !important {
    line-height: 1.6 !important;
    font-weight: 300 !important; }
		&.active {
			background-color: #223A66 !important;
			color: #fff !important; } } }
.railContent {
	border-left: 1px solid lightgray !important; }


.projectInfo {
	width: 100%;
	.item {
		padding: 0 !important;
		border: 1px solid lightgray;
		i.icon {
			padding: 16px 10px !important;
			border-right: 1px solid lightgray;
			&:before {
				padding: 10px !important; } }
		.content {
			padding-left: 14px !important;
			.description {
				font-size: 18px;
				font-weight: 100; } }
		&:first-of-type {
			border-bottom: 0;
			i.icon {
				padding: 16px 11px 16px 12px !important; } }
		&:last-of-type {
			border-top: 0;
			i.icon {
				padding: 16px 14px 16px 14px !important; } } } }



// Mobile
@media screen and (max-width: 767px) {
	.railContent {
		border-left: none !important; } }
// Tablet
@media (max-width: 991px) and (min-width: 768px) {
	.whatwedoContainer {
		margin: 0 !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		width: 100% !important; }
	.railContent {
		border-left: none !important; } }

.accordion.projectsAccordion .title {
  .left.content {
    background-color: #f9f9f9; }
  background-color: white;
  &.active {
    .left.content {
      background-color: white;
      transition: 1s ease-in-out; }
    h3.header {
 }      // color: white !important
    background-color: #223A66; } }

.accordion.projectsAccordion .title {
  .ui.left.content {
    float: left;
    background-color: #ededed;
    border-right: 1px solid lightgray;
    i {
      margin: 15px 15px !important;
      color: black;
      font-size: 20px !important; } } }

.accordion.projectsAccordion .title {
  h3.header {}
  background-color: #f2f2f2;
  padding: 0 !important;
  height: 50px !important;
  .header {
    top: 14px !important;
    margin-left: 20px;
    position: relative; }
  &.active {
    background-color: #223A66 !important;
    color: white !important;
    .left.content i {
      color: #223A66 !important; }
    .header {
      color: white !important; } } }

.accordion.projectsAccordion .title .header {
  font-size: 18px !important;
  display: inline;
  font-weight: 700; }
.header .content a > p {
  font-size: 38px;
  line-height: 1;
  letter-spacing: 1.6px; }
.accordion.projectsAccordion .content {
  font-size: 16px;
  font-weight: 100;
  color: #5a5a5a; }


// mobile view
@media screen and (max-width: 767px) {
  .accordion.projectsAccordion .title h3.header {
    font-size: 14px !important; } }

// tablet view
@media (max-width: 991px) and (min-width: 768px) {
  .accordion.projectsAccordion .title h3.header {
      font-size: 14px !important; } }

// laptop view
@media (max-width: 1201px) and (min-width: 992px) {
  .accordion.projectsAccordion .title h3.header {
      font-size: 16px !important; } }
