#map {
	height: 1000px;
	width: 100%;
	position: absolute !important;
	top: 0;
	z-index: 0 !important; }

.info {
	.row:nth-of-type(1) {
		.column {
			ul {
				list-style-type: none;
				li {
					padding: 4px 0;
					a {
						font-size: 16px; } } } } }
	.row:nth-of-type(2) {
		padding: 10px 0 !important;
		h3 {
			font-size: 18px !important; } } }
